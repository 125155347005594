import React from 'react';
import '../styles/About.css';

const About = () => {
  const stats = [
    { number: '10+', text: 'Ani de Experiență' },
    { number: '1000+', text: 'Clienți Mulțumiți' },
    { number: '500+', text: 'Proiecte Finalizate' }
  ];

  const features = [
    'Expertiză tehnică avansată',
    'Soluții personalizate',
    'Calitate garantată',
    'Suport tehnic specializat'
  ];

  return (
    <section id="despre" className="about-section">
      <div className="container">
        <div className="about-grid">
          <div className="about-content">
            <h2>Expertiză și Inovație</h2>
            <p>
              Cu o experiență vastă în domeniu, GEOSAW s-a remarcat prin
              oferirea unor soluții tehnice inovatoare în domeniul prelucrării
              materialelor și ingineriei mecanice.
            </p>
            <ul className="feature-list">
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="stats-grid">
            {stats.map((stat, index) => (
              <div key={index} className="stat-card">
                <h3>{stat.number}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;