import React from 'react';
import '../styles/Hero.css';

const Hero = () => {
  return (
    <section id="home" className="hero-section">
      <div className="container">
        <div className="hero-content">
          <h1>
            Materiale și
            <span className="highlight"> Soluții</span>
            <br />Inovatoare în <br />
            <span className="gradient-text">Inginerie</span>
          </h1>
          {/* <div className="project-info">
            <p><strong>Titlu proiect</strong> - "DIGITALIZARE GEO'SAW SRL"</p>
            <p><strong>Cod SMIS</strong> - 312180</p>
            <p><strong>Beneficiar</strong> - SC GEO'SAW SRL, CUI 15796490, J33/906/2003</p>
            <p><strong>Scop proiect</strong> - Proiectul contribuie la realizarea obiectivului specific al apelului RSO1.2 – FEDR – RSO1.2_Valorificarea avantajelor digitalizării, în beneficiul cetățenilor, al companiilor, al organizațiilor de cercetare și al autorităților publice, intrucat prin realizarea investitiei propuse de catre beneficiar, acesta urmareste digitalizarea societatii comerciale.</p>
            <p><strong>Rezultate</strong> - Achizitionarea de echipamente hardware TIC; Achizitionarea de software personalizat</p>
            <p><strong>Perioada de implementare</strong> - de la 24.04.2024 pana la 24.04.2025.</p>
            <p className="funding-info">Proiect cofinantat din Fondul European de Dezvoltare Regionala prin Regional Nord-Est 2021-2027.</p>
          </div> */}
          <div className="hero-buttons">
            <a href="#contact" className="primary-btn">Contactează-ne</a>
            <a href="#servicii" className="secondary-btn">Descoperă Serviciile</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;