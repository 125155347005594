import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import ChatSystem from './components/ChatSystem';
import Contact from './components/Contact';
import FloatingIcons from './components/FloatingIcons';
import SplineContainer from './components/SplineContainer';
import Footer from './components/Footer';
import './styles/base.css';

function App() {
  return (
    <>
      <SplineContainer />
      <div className="content-wrapper">
        <Navbar />
        <Hero />
        <Services />
        <About />
        <Contact />
        <Footer />
        <FloatingIcons />
        <ChatSystem />
      </div>
    </>
  );
}

export default App;