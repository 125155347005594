import React from 'react';
import '../styles/FloatingIcons.css';

const FloatingIcons = () => {
  return (
    <div className="floating-icons">
      <a
        href="https://wa.me/40744183509"
        className="floating-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000"
          alt="WhatsApp"
        />
      </a>
      <a href="tel:+40744183509" className="floating-icon">
        <img
          src="https://img.icons8.com/?size=100&id=124195&format=png&color=000000"
          alt="Phone"
        />
      </a>
    </div>
  );
};

export default FloatingIcons;