import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2 className="section-title">Contact</h2>
        <div className="contact-grid">
          <div className="contact-info">
            <div className="contact-card">
              <h3>Informații Contact</h3>
              <div className="contact-details">
                <div className="contact-item">
                  <h4>Adresă</h4>
                  <p>
                    Strada Scânteii, Nr. 973<br />
                    Cajvana, Județ Suceava<br />
                  </p>
                </div>
                <div className="contact-item">
                  <h4>Email</h4>
                  <p>geo.saw.cj@gmail.com</p>
                </div>
                <div className="contact-item">
                  <h4>Telefon</h4>
                  <p>0744 183 509</p>
                </div>
                <div className="contact-item">
                  <h4>Social Media</h4>
                  <div className="social-links">
                    <a href="https://www.facebook.com/GeoSawSrl" target="https://www.facebook.com/GeoSawSrl" rel="noopener noreferrer">
                      <i className="fab fa-facebook"></i> Facebook
                    </a>
                    <a href="https://www.linkedin.com/in/geosaw-depozit-materiale-098b7033a/" target="https://www.linkedin.com/in/geosaw-depozit-materiale-098b7033a/" rel="noopener noreferrer">
                      <i className="fab fa-linkedin"></i> LinkedIn
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2691.0461009346786!2d25.96714067678636!3d47.62999238918561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473476d3145ecf67%3A0x4524a1dd661966eb!2sCajvana!5e0!3m2!1sro!2sro!4v1698674532703!5m2!1sro!2sro"
                width="100%"
                height="300"
                style={{ border: 0, borderRadius: '15px' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="contact-form">
            <h3>Trimite-ne un mesaj</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" placeholder="Numele tău" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email-ul tău" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Mesajul tău" required />
              </div>
              <button type="submit" className="submit-btn">
                Trimite Mesaj
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;