import React, { useState } from 'react';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <a href="#" className="navbar-brand">
          <img src="./LOGO-GEOSAW.png" alt="GEOSAW" className="brand-logo" />
        </a>
        
        <button 
          className={`menu-toggle ${isActive ? 'active' : ''}`} 
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
    
        <div className={`nav-outer ${isActive ? 'active' : ''}`}>
          <a href="https://dashboard.depozit-materiale.com" className="theme-btn">
            LOGIN
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;