import React from 'react';

const SplineContainer = () => {
  return (
    <div className="spline-container">
      <iframe
        src="https://my.spline.design/wwdc24landingpagedesign-83907f4151667bef8befd510184cc76a/"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default SplineContainer;