import React from "react";
import "../styles/Footer.css";

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE GEO’SAW SRL.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="info-section">
          <p>
            Pentru informații detaliate despre celelalte programe cofinanțate de
            Uniunea Europeană, vă invităm să vizitați
            <a href="https://www.mfe.gov.ro" className="info-link">
              {" "}
              www.mfe.gov.ro
            </a>
          </p>
        </div>
        <div className="web-links">
          <div className="url-links">
            <a href="http://www.regionordest.ro" className="web-link">
              www.regionordest.ro
            </a>
            <a
              href="https://www.facebook.com/Regio.NordEst.ro"
              className="web-link"
            >
              www.facebook.com/Regio.NordEst.ro
            </a>
          </div>
          <div className="aproape-button">Aproape de tine</div>
          
        </div>

          <div className="footer-banner">
            <div className="logo-row">
              <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
              <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
              <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
              <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
            </div>
            <button onClick={handleInfoClick} className="info-button">
              Proiect digitalizare - Află mai multe
            </button>
          </div>
      </div>
    </footer>
  );
};

export default Footer;
