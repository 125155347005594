import React from 'react';
import '../styles/Services.css';

const Services = () => {
  const services = [
    {
      icon: '🌳',
      title: 'Comerț cu Material Lemnos',
      description: 'Distribuție și comercializare de materiale lemnoase de înaltă calitate'
    },
    {
      icon: '🏗️',
      title: 'Materiale de Construcții',
      description: 'Distribuție completă de materiale pentru construcții'
    },
    {
      icon: '🚰',
      title: 'Echipamente Sanitare',
      description: 'Comercializare echipamente și accesorii sanitare'
    }
  ];

  return (
    <section id="servicii" className="services-section">
      <div className="container">
        <h2 className="section-title">Serviciile Noastre</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-icon">{service.icon}</div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;